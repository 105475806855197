@import "../variables";

.Main {
  padding: $spacer;
  position: relative;
  z-index: 1;
  min-height: calc(100% - 60px);

  .main {
    position: relative;
    z-index: 1;
  }
}
