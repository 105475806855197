.Acts {
    &_noResults {
        &Wrapper {
            position: relative;
            margin-top: 40px;
        }

        &Inner {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
        }

        &_text {
            width: 200px;
        }
    }

    &_clearButton {
        background-color: var(--currentContrast);
        color: var(--white);
        width: 40px;
        height: 40px;
        min-width: auto;
        font-size: 24px
    }
}
