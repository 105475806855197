@import "../variables";

.Button {
  display: inline-block;
  margin: $spacer/2 $spacer;
  min-width: 100px;
  text-align: center;
  padding: #{$spacer / 4} #{$spacer / 2};
  border-radius: 5px;
  border: 1px solid var(--currentContrast);
  line-height: 30px;
  background: var(--current);
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &-transparent {
    background: rgba(255,255,255, 0.1);
    color: var(--white);
    border-color: var(--white);

    &:hover {
      background: rgba(255,255,255, 0.2);
    }
  }
}
