.u {
  &-text-center {
    text-align: center;
  }
  &-centre {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-minWidth0 {
    min-width: 0;
  }
}
