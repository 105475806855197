@import "../variables";

.Header {
  margin-bottom: 0;
  line-height: 39px;
  padding: 0 0 0 5px;
  background: $light-header-gradient, url('../../../public/images/pyramid-sunset-blur-dark.jpg') no-repeat top center fixed var(--background);
  background-size: cover;
  display: flex;
  height: 60px;

  @media(max-width: 400px) {
    height: 40px;
  }

  h3 {
    margin: 0 0 0 2px;
    font-size: 24px;
    line-height: 59px;
    padding-left: $spacer;
    color: var(--backgroundContrast);
  }

  a {
    color: var(--backgroundContrast);
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &-logo {
    width: 60px;
    height: 60px;

    img {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 400px) {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 420px) {
    h3 {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 359px) {
    font-size: 14px;
  }
}
