@import "../variables";

.ButtonGroup {
  display: flex;
  flex-wrap: nowrap;

  .Button {
    margin: 0;

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.isInactive {
      background-color: #ccc;
      color: #666;
      border-color: #666;
    }
  }
}
