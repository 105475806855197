.Maps {
  position: relative;
  min-height: 100%
}

.header {
  font-size: 18px;
  display: inline-block;
}

.map {
  border: 1px solid white;
  border-radius: 5px;
  position: fixed;
  bottom: 12px;
  top: 152px;
  right: 12px;
  left: 12px;
  background-color: white;

  @media (max-width: 430px) {
    top: 162px;
  }
}

.buttonGroup {
  margin-bottom: 12px;
  display: inline-block;
  margin-left: 12px;

  @media (max-width: 430px) {
    margin-left: 0;
  }
}
