@import "../../styles/variables";

.Nav {
  display: flex;
  margin: 0;
  align-self: flex-end;
  position: absolute;
  right: 0;

  @media(max-width: 400px) {
    margin: auto;
    position: static;
  }

  &-item {
    list-style: none;
    height: 40px;
    margin-right: $spacer;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    transition: border 0.2s ease-in-out;
    border-color: var(--current);
    padding-bottom: 7px;
    display: block;

    &:hover,
    &.isActive {
      text-decoration: none;
      border-bottom: 7px solid var(--current);
      padding-bottom: 0;
    }

    &.isPending {
      border-bottom: 7px solid var(--warning);
    }

    &:last-child {
      @media(max-width: 400px) {
        margin-right: 0;
      }
    }
  }
}
