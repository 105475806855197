@import "../../styles/variables.scss";

.Stages {
  &-heading {
    font-size: 18px;
    display: block;
  }

  &-list {
    margin: auto;
    text-align: center;
    display: grid;
    justify-content: space-evenly;
    align-items: center;
    justify-items: stretch;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0px 16px;
  }

  .StageChip {
    a {
      width: calc(100% - 20px);
    }
  }

  .Search {
    margin-bottom: $spacer;

    .Input {
      margin: 0;
      width: 100%;
    }
  }
}
