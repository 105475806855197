@import "../variables";

.ActChip {
  display: inline-flex;
  margin: $spacer/2 0;
  padding: #{$spacer / 4} #{$spacer / 2};
  border-radius: 5px;
  border: 1px solid var(--currentContrast);
  line-height: 30px;
  background: $light-gradient;

  &-button {
    padding: 0 $spacer/2;
    margin: 0 #{$spacer/2} 0 -#{$spacer/4};
    font-size: 1.2rem;
    border-radius: 3px;
    background: rgba(255,255,255, 0.1);
    color: var(--white);
    cursor: pointer;
    border: 1px solid transparent;

    &.isSelected {
      background: var(--currentContrast);
      border: 1px solid #ccc;
    }

    &:hover {
      background: rgba(255,255,255, 0.2);
    }
  }

  a {
    color: $white;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    align-self: center;
  }
}
